<template>
  <div class="multiview">
    <div class="multiview__page">
      <MultiviewHeader />
      <MultiviewTable />
      <EventDrawer
        v-if="isDrawerVisible"
      />
      <Modal
        class="multiview__modal"
        :visible="!!eventToRemove"
        title="Remove event from Multiview?"
        @close="cancelEventRemoval"
      >
        <div class="modal__text">
          If you proceed, '{{ formatEventName(eventToRemove) }}' will not be visible on Multiview anymore.
          <br>
          But you can fetch it back anytime.
        </div>
        <div class="modal__footer">
          <Button
            @click="cancelEventRemoval"
          >
            Dismiss
          </Button>
          <Button
            variant="primary"
            @click="confirmEventRemoval"
          >
            Remove
          </Button>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import {
  ref, computed, watch, onMounted, onBeforeUnmount, provide,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import {
  isNil, isArray, filter, isEqual, pickBy, identity, split, join, reverse, cloneDeep, includes,
} from 'lodash';
import { useOnlineStatus } from '@/composables';
import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import MultiviewHeader from './header/MultiviewHeader';
import MultiviewTable from './table/MultiviewTable';
import EventDrawer from './event-drawer/EventDrawer';

const sortOptions = ['STARTS_AT_ASC', 'STARTS_AT_DESC', 'NATURAL'];

export default {
  components: {
    Modal,
    Button,
    MultiviewHeader,
    MultiviewTable,
    EventDrawer,
  },
  setup() {
    const route = useRoute();
    const eventIds = computed(() => {
      const { ids } = route.query;
      if (isNil(ids)) return null;
      if (isArray(ids)) return ids;
      return [ids];
    });
    const status = computed(() => route.query.status || '');
    const sort = computed(() => route.query.sort || '');
    const event = computed(() => route.query.event || '');

    const store = useStore();
    const loadEvents = () => {
      store.dispatch('loadMultiviewEventList', {
        eventIds: eventIds.value,
        sort: sort.value,
        status: status.value,
      });
    };

    const router = useRouter();
    const eventToRemove = ref(null);
    const preventReload = ref(false);
    const isDrawerVisible = computed(() => !!event.value);
    const removeEvent = (eventId) => {
      const { eventName, isUsaView } = store.getters.multiviewListEventsById[eventId];
      eventToRemove.value = { eventId, eventName, isUsaView };
    };
    const formatEventName = ({ eventName, isUsaView }) => {
      const competitors = split(eventName, ' v ');
      if (isUsaView) return join(reverse(competitors), ' @ ');
      return join(competitors, ' vs ');
    };
    const cancelEventRemoval = () => {
      eventToRemove.value = null;
    };
    const confirmEventRemoval = async () => {
      preventReload.value = true;
      const ids = filter(eventIds.value, (eventId) => eventId !== eventToRemove.value.eventId);
      const pinned = filter(route.query.pinned || [], (eventId) => eventId !== eventToRemove.value.eventId);
      await router.replace({
        name: route.name,
        query: pickBy({
          ...route.query,
          ids,
          pinned,
        }, identity),
      });
      store.dispatch('removeMultiviewEventListEvent', eventToRemove.value.eventId);
      eventToRemove.value = null;
      preventReload.value = false;
    };
    const openDrawer = (eventId, drawerTab) => {
      router.replace({
        name: route.name,
        query: pickBy({
          ...route.query,
          event: eventId,
          tab: drawerTab,
        }, identity),
      });
    };
    provide('openDrawer', openDrawer);
    const closeDrawer = () => {
      const {
        event: prevEventId,
        tab: prevTab,
        ...queryWithoutDrawerParams
      } = route.query;
      router.replace({
        name: route.name,
        query: pickBy(queryWithoutDrawerParams, identity),
      });
    };
    provide('closeDrawer', closeDrawer);

    watch(sort, (newSort, oldSort) => {
      if (route.name !== 'trading-ui-multiview' || newSort === oldSort) return;
      loadEvents();
    });
    watch(
      eventIds,
      (newEventIds, oldEventIds) => {
        if (preventReload.value) {
          preventReload.value = false;
          return;
        }
        if (route.name !== 'trading-ui-multiview' || isEqual(newEventIds, oldEventIds)) return;
        loadEvents();
      },
    );

    onMounted(() => {
      loadEvents();

      if (sort.value && !includes(sortOptions, sort.value)) {
        const query = cloneDeep(route.query);
        delete query.sort;
        router.replace({
          name: route.name,
          query: pickBy(query, identity),
        });
      }
    });
    onBeforeUnmount(() => {
      if (store.getters.multiviewListEventIds?.length) {
        store.dispatch('unsubscribeMultiviewEventList');
        store.dispatch('clearMultiviewEventList');
      }
      if (store.getters.multiviewDrawerEvent) {
        store.dispatch('clearMultiviewDrawer');
      }
    });

    provide('removeEvent', removeEvent);
    provide('loadEvents', loadEvents);

    useOnlineStatus({
      onReconnect() {
        store.dispatch('reloadMultiviewEventList');
      },
    });

    return {
      isDrawerVisible,
      openDrawer,
      eventToRemove,
      formatEventName,
      removeEvent,
      cancelEventRemoval,
      confirmEventRemoval,
    };
  },
};
</script>

<style lang="scss">
.multiview {
  background-color: #fafafa;
  position: relative;
  padding: 8px 0 0;

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9000;
    background-color: rgba(255, 255, 255, 0.5);
  }

  &__page {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    position: relative;
  }

  &__modal {
    .modal__dialog {
      width: 360px;
    }

    .modal__header {
      padding: 16px;
      border-bottom: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
    }

    .modal__title {
      padding: 0;
      color: var(--neutral-text-default-resting, #191414);
      font-family: Poppins;
      font-size: 15.72px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .modal__content {
      padding: 0;
    }

    .modal__text {
      padding: 16px;
    }

    .modal__footer {
      padding: 16px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;
    }
  }
}
</style>
